"use client"

import React from 'react';
import AssetUploadProvider from 'src/providers/AssetUploadProvider';
import { SessionProvider } from 'next-auth/react';
import SiteHeader from 'src/components/sections/SiteHeader';
import AssetUpload from 'src/components/sections/AssetUpload';
import { B2C } from 'src/constants';

function HomePage() {
    return (
        <SessionProvider
            // Refetch the session when it's ready to expire, which will trigger a refresh
            // It also will refetch when the window is focused
            refetchInterval={B2C.ID_TOKEN_AGE}
            // Disable session refetch if the user is offline, otherwise it returns an invalid session
            refetchWhenOffline={false}
        >
            <AssetUploadProvider >
            <main>
                
                <SiteHeader title="Upload Your Content" />
                <AssetUpload />

                </main>
            </AssetUploadProvider>
        </SessionProvider>
    );
}

export default HomePage;
