/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

'use client';

import React from 'react';
import { useSession } from 'next-auth/react';

import Uploader from 'src/components/modules/Uploader';
import AssetTableExisting from 'src/components/modules/AssetTableExisting';
import Help from 'src/components/modules/Help';

import styles from './AssetUpload.module.scss';

function AssetUpload() {
    const { data: session } = useSession();
    return (
        <div>
            {session ? (
                <div className={styles.container}>
                    <p>You can drag and drop or select files to upload in the section below. Files should meet the following specifications:</p>
                    <ul>
                        <li><b>Digital Only:</b> 150dpi @ 100%</li>
                        <li><b>Print Syndication:</b> CMYK, 300 dpi @ 100% no compression or 600 dpi @ 100% LZW Compression</li>
                        <li><b>Comic Art Prints:</b> CMYK, 600 dpi @ 100% LZW Compression</li>
                        <li><b>Irregular-sized comics for print:</b> CMYK, 600dpi @ 100% at their given dimensions and aspect ratio, with LZW compression</li>
                        <li><b>Minimum pixel dimensions:</b>
                            <ul>
                                <li>38.5 picas/3750 pixels/6.25 inches in width for dailies</li>
                                <li>76.8 picas/ 7680 pixels/12.8 inches in width for Sunday strips</li>
                            </ul>
                        </li>
                    </ul>


                    <p>Please follow the naming conventions below for file processing and identification:</p>

                    <ul>
                        <li><b>Short Date Format:</b> feature code (xyz), year (yy), month (mm), day (dd) Ex: xyz240501.tif</li>
                        <li><b>Long Date Format:</b> feature code (xyz), year (yyyy), month (mm), day (dd) Ex: xyz20240501.tif</li>
                    </ul>
                    <p>
                        Please stick with one date format or the other - switching date
                        formats will cause issues in the system.
                    </p>
                    <p>
                        For issues, check our FAQs or contact{' '}
                        <a href="mailto:opal_support@amuniversal.com" title="opal_support@amuniversal.com">
                            OPAL support
                        </a>.
                    </p>
                    <br />
                    <div>
                        <Uploader />
                        <AssetTableExisting />
                    </div>
                </div>
            ) : (
                <div className={styles.drop}>
                    <div className={styles.dropPad}>
                        Welcome to AMU Opal. You are currently signed out. If you are not
                        automatically redirected to the sign-in page, click the Sign In
                        button above to continue.
                    </div>
                </div>
            )}
            <Help />
        </div>
    );
}

export default AssetUpload;
