import React, { useState, useEffect,memo } from 'react';
import Image from 'next/image';
import { FileIcon, defaultStyles } from "react-file-icon";
import Zoom from 'react-medium-image-zoom'
import classNames from 'classnames';
import axios from 'axios';

import { useAssetUploadContext } from 'src/providers/AssetUploadProvider';
import consoleLogger from 'src/helpers/utilities/consoleLogger';


import 'react-medium-image-zoom/dist/styles.css'
import styles from './AssetTableExisting.module.scss';



const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items];

        if (!sortConfig) {
            setSortConfig({ key: "createdOn", direction: "descending" })
        }

        if (sortConfig !== null) {
            if (sortConfig.key != "createdOn") {

                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            else {
                sortableItems.sort((a, b) => {
                    if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });  
    };

    return { items: sortedItems, requestSort, sortConfig };
};

function AssetTableExisting() {

    const [existingFiles, setExistingFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    const { rerender, setRerender } = useAssetUploadContext();

    const imageTypes = ["jpg", "jpeg", "png", "png", "gif"];

    const loadExistingFiles = async () => {

        try {
            setExistingFiles([]);
            setLoading(true);
            const response = await axios.get(`api/assets`, {
                headers: {
                    'Ocp-Apim-Subscription-Key':
                        process.env.NEXT_PUBLIC_API_SUBSCRIPTION_KEY,
                },
            });
            if (response && response.status === 200) {
                const files = response.data.message;
                setExistingFiles(files);
                setLoading(false);
                return files;
            }

            return response;
        } catch (error) {
            consoleLogger('loadExistingFiles error:', error);
            return error;
        }
    };

    const formatFileSize = (size) => {
        const sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / (1024 ** i)).toFixed(2)} ${sizes[i]}`
    };

    const previewIconRemote = (file) => {
        const fileExt = file.name.split('.').pop();
        let img;
        if (imageTypes.includes(fileExt)) {

            img = (<Zoom>
                <img
                    alt={file.name}
                    height="50"
                    src={file.url}
                />
            </Zoom>);
        }
        else {
            img = (<div className={styles.icon}>
                <FileIcon extension={fileExt}  {...defaultStyles[fileExt]} />
            </div>
            );
        }

        return img;
    };

    const { items, requestSort, sortConfig } = useSortableData(existingFiles);

    const sortByColumn = (name) => {
        if (!sortConfig) {
            return;
        }

        return sortConfig.key === name && sortConfig.direction ? classNames(styles[sortConfig.direction]) : undefined;
    };

    const downloadFile = (filename, content) => {
        const element = document.createElement("a");
        element.setAttribute("href", content);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    useEffect(() => {
        loadExistingFiles();       
    }, [rerender]);

    return (
        <table className={styles.fileListTable}>
            <thead>
                <tr>
                    <th colSpan={7}>Recently Uploaded Files</th>
                </tr>
                <tr>
                    <th className={styles.preview}>Preview</th>
                    <th className={styles.filename}><button
                        type="button"
                        onClick={() => requestSort('name')}
                        className={sortByColumn('name')}>
                        File Name 
                    </button></th>
                    <th className={styles.dateAdded}><button
                        type="button"
                        onClick={() => requestSort('createdOn')}
                        className={sortByColumn('createdOn')}>
                        Date Added
                    </button></th>
                    <th className={styles.dateAdded}><button
                        type="button"
                        onClick={() => requestSort('uploadedBy')}
                        className={sortByColumn('uploadedBy')}>
                        Added By
                    </button></th>
                    <th className={styles.dateAdded}><button
                        type="button"
                        onClick={() => requestSort('size')}
                        className={sortByColumn('size')}>
                        Size
                    </button></th>
                    <th className={styles.status}><button
                        type="button"
                        onClick={() => requestSort('status')}
                        className={sortByColumn('status')}>
                        Status
                    </button></th>
                    <th className={styles.action}>Download</th>
                </tr>

            </thead>
            <tbody>
                <tr className={!loading ? styles.hidden : null}>
                    <td className={styles.loading} colSpan={7}>
                        <Image
                            className={styles.loadinggif}
                            alt="Loading"
                            height={50}
                            src="https://cisassets.blob.core.windows.net/opal-page-assets/loading-gif.gif"
                            width={50}
                        />
                    </td>
                </tr>
                {items.map((file) => (
                    <tr key={file.name}>
                        <td>{previewIconRemote(file)}</td>
                        <td className={styles.field}>{file.name}</td>
                        <td className={styles.field}>{file.createdOn}</td>
                        <td className={styles.field}>{file.uploadedBy}</td>
                        <td className={styles.field}>{formatFileSize(file.size)}</td>
                        <td className={styles.field}>{file.status}</td>
                        <td>
                            <Image
                                alt="Download"
                                className={styles.actionImg}
                                height={15}
                                src="/images/download.svg"
                                width={15}
                                onClick={() => downloadFile(file.name, file.url)}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default AssetTableExisting;