import { createContext, useContext, useState } from 'react';

const AssetUploadContext = createContext();


export default function AssetUploadProvider({ children }) {

    const [rerender, setRerender] = useState(true); // [rerender, setRerender]

    return (
        <AssetUploadContext.Provider value={{ rerender, setRerender }}>
            {children}
        </AssetUploadContext.Provider>
    );
};

export function useAssetUploadContext() {
    return useContext(AssetUploadContext);
}